import Hero from "./Hero/Hero";
import Skills from "./Skills";
import WorkExperience from "./WorkExperience/Workexperience";
import ContactMe from "./ContactMe/ContactMe";

const MainPage = () => {
    return (
        <div>
          <Hero />
          <Skills />
          <WorkExperience />
          <ContactMe/>
        </div>
    )
}

export default MainPage;