import "./MobileNav.css"
import { Link } from "react-router-dom";

const MobileNav = ({ isOpen, toggleMenu }) => {
    return (
        <>
            <div
                className={`mobile-menu ${isOpen ? "active": ""}`}
                onClick={toggleMenu}
            >
                <div className="mobile-menu-container">
                     <img className="logo" src="./assets/images/logo.png" alt="logo.pictures" />
                     <ul>
                            <li>
                                <Link to="/" className="menu-item">Strona Główna</Link>
                            </li>                           
                            <li>
                                <a className="menu-item" href="#przykladowe-wspomnienia-link">Przykładowe wspomnienia</a>
                            </li>                            
                            <li>
                                <a className="menu-item" href="#contact-us-link" >Skontaktuj się z nami</a>
                            </li>
                            <button className="contact-btn" onClick={() => {}}>
                                 Stwórz Wspomnienie
                            </button>
                        </ul>
                </div>
            </div>
        </>
    )
}
export default MobileNav;